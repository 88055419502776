<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      "Value is required.": "Valor é obrigatório",
      Rules: "Regras",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Status",
      Payment: "Pagamento",
      Details: "Detalhes",
      Pending: "Pendente",
      Approved: "Aprovado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "Value is required.": "Se requiere valor.",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Estado",
      Payment: "Pago",
      Details: "Detalles",
      Pending: "Pendiente",
      Approved: "Aprobado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
  },
  data() {
    return {
      loading: {
        quota: false,
      },

      quota: {
        alert: { type: "", message: "" },
        price: "0.00",
        total: "1",
        promotions: [],
        promotion: 0,
      },

      table: {
        heade: ["Order", "Date", "Total", "Status", ""],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  validations: {
    quota: {
      total: { required },
    },
  },
  methods: {
    getPrice() {
      api.get("quotas/price").then((response) => {
        if (response.data.status == "success") {
          this.quota.price = response.data.price;
        }
      });
    },
    buySubmit() {
      this.$v.$touch();

      if (this.quota.total > 0) {
        this.loading.quota = true;

        api
          .post("quotas", {
            total: this.quota.total,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.quota.total = "1";
              this.$v.$reset();

              this.quota.alert.type = "alert-success";
              this.quota.alert.message = response.data.message;

              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.quota.alert.type = "alert-default";
              this.quota.alert.message = response.data.message;
            }

            this.loading.quota = false;
          });
      }
    },
    getOrders() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("quotas/orders")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getPrice();
    this.getOrders();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Cotas") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body bg-default rounded p-4 text-white">
            <h4 class="text-white">Benefícios</h4>
            <ul class="m-0 p-0 pl-4">
              <li>15% dos lucros dos shows;</li>
              <li>50% do valor Pago do A-SHOW VIP em tokens;</li>
              <li>Cartão físico, acesso aos shows de maneira vip;</li>
              <li>
                Acesso ao camarim e camarote vip. (Sujeito a limite por pessoas
                por evento);
              </li>
              <li>
                Detentores terão 50% do valor do ingresso devolvido (em tokens)
                uma vez que ele for ao evento e confirma sua presença.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="buySubmit">
              <div
                v-if="quota.alert.message"
                :class="'alert ' + quota.alert.type"
              >
                {{ t(quota.alert.message) }}
              </div>
              <b-form-group
                id="total"
                :label="t('Digite a quantidade de cotas que deseja comprar')"
                label-for="total"
              >
                <b-form-input
                  id="total"
                  v-model="quota.total"
                  type="number"
                  min="1"
                  :class="{ 'is-invalid': $v.quota.total.$error }"
                ></b-form-input>
                <div v-if="$v.quota.total.$error" class="invalid-feedback">
                  <span v-if="!$v.quota.total.required">{{
                    t("Value is required.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="loading.quota == true || this.quota.total < 0"
                  type="submit"
                  variant="default"
                >
                  {{ t("Comprar") }}
                  <b-spinner
                    v-if="loading.quota"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-soft-default rounded-top">
            Meus Pedidos
          </div>
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="row">
                      <div v-if="row === 'id'">
                        <strong>#{{ data }}</strong>
                      </div>
                      <div v-else-if="row === 'total'">
                        {{ data | currency }}
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                    <td class="text-right">
                      <router-link
                        :to="'/store/order/' + td.id"
                        class="btn btn-default btn-sm btn-rounded"
                      >
                        {{ t("View") }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
